// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-additions-and-renovations-js": () => import("./../src/pages/additions-and-renovations.js" /* webpackChunkName: "component---src-pages-additions-and-renovations-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-we-work-js": () => import("./../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-builds-js": () => import("./../src/pages/new-builds.js" /* webpackChunkName: "component---src-pages-new-builds-js" */),
  "component---src-pages-reclads-js": () => import("./../src/pages/reclads.js" /* webpackChunkName: "component---src-pages-reclads-js" */)
}

